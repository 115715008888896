$break-mobile: 400px;
$break-tablet: 768px;
$break-large: 1200px;


.projectContainer {
    margin-bottom: 5%;

    .sectionHeader {
        font-weight: bold;
        text-transform: uppercase;
    }

    .skillIconContainer {
        width: 100%;

        .skillIcon {
            height: 40px;
            margin: 1rem 1rem 2rem 0;
        }
    }

    .projects {
        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
        justify-content: flex-start;
        border-top: 1px solid black;
        padding-top: 1rem;

        .projectCard {
            flex-basis: 300px;
            max-width: 50%;
            min-width: 25%;
            flex-grow: 1;
            margin: 1%;

            @media screen and (max-width: $break-tablet) {
                max-width: 100%;
            }
            
            .card-img {
                padding: 2%;
            }

            .card-body {
                display: flex;
                flex-flow: column nowrap;
                text-align: center;
            }        
        }
    }
}

.web-projects {
    .design-projects {
        display: none;
    }
}

.design-projects {

    .web-projects {
        display: none;
    }

}


.projectButtons {
    justify-content: flex-end;
}
.projectCard button {
    width: 100%;
    margin-bottom: 1.5%;
    background-color: white;
    border: 1px solid grey;
    color: grey;
}

.projectCard h1 {
    font-size: 1.8rem;
    font-weight: bold;
    text-transform: uppercase;
}

.project-details, .container {

    @media screen and (max-width: $break-mobile) {
        padding: 0;
    }

    .project-intro {

        @media screen and (max-width: $break-mobile) {
            padding: 0;
        }
    

        .project-title {
            display: flex;
            flex-flow: row;
            background-color: white;

            @media screen and (max-width: $break-tablet) {
                display: block;
                padding: 0;
                padding-top: 50px;
            }

            .project-info {
                width: 70%;
                padding-left: 60px;

                button {
                    margin-top: 20px;
                }

                @media screen and (max-width: $break-large) {
                    width: 50%;
                }
                
                @media screen and (max-width: $break-tablet) {
                    width: 100%;
                    padding: 0;
                    text-align: center;
                }
            }

            .project-image {
                width: 30%;

                @media screen and (max-width: $break-large) {
                    width: 50%;
                }

                @media screen and (max-width: $break-tablet) {
                    width: 100%;
                }

                .project-image-display {
                    width: 100%;
                }
            }
        }
    }

    .project-description {
        border-top: 1px solid black;
        padding: 20px 0;
    }

    .project-screens {
        width: 100%;
        border-top: 1px solid black;
        padding: 20px 0;
        
        .carousel {
            margin-top: 20px
        }

        .carousel-item img {
            width: 100%;
        }
    }

    .project-technology {

        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1px solid black;
        padding: 20px 0;


        .project-technology-logo-container {
            display: flex;
                        
            .project-technology-logo {

                display: flex;
                flex-flow: column;
                justify-content: center;
                margin-top: 10px;
                padding-right: 15px;

                img {
                    width: 50px;
                }
            }
        }

    }
}

.logo-cards {

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    @media screen and (max-width: $break-large) {
        justify-content: center;
    }

        .design-card {
            flex-basis: 300px;
            flex-grow: 0;
            margin: 10px;
            cursor: pointer;

            @media screen and (max-width: $break-tablet) {
                flex-grow: 1;
            }
        
            .design-card-img {
                border-radius: 0;
            }
        }
    }

.design-card-modal {

    .design-card-modal-img {
        width: 100%;
        margin-bottom: 30px;
    }

    .project-info {

        .project-container {
            margin-bottom: 40px;

        }

        .project-colors {
            display: flex;
            flex-flow: row nowrap;
    
            .project-color {
                height: 100px;
                flex-grow: 1;
                background-color: grey;
            }
        }

        .project-container {
            border-top: 1px solid #6b6b6b;
            padding-top: 20px;

            h5 {
                font-weight: 400;
                color:#6b6b6b;
            }

            .project-asset {
                margin-bottom: 20px;
            }
        }
    

    }
}

.video-production-cards {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;

    .video-card {
        flex-grow: 1;
        margin: 10px;
    }
}