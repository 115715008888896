$break-small: 320px;
$break-tablet: 768px;
$break-large: 1200px;

.container {
  padding: 2rem 1rem;
  
}

.heroBanner {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height:100vh;
  width: 100%;
  padding: 0 1rem;
  background: transparent linear-gradient(1deg, #FFFFFF 5%, #A2A2A2 100%) 0% 0% no-repeat padding-box;
  text-align: center;
}

.heroImage {
  width: 250px;
  height: 250px;
  border-radius: 125px;
  border: 2px solid white;
  box-shadow: 0px 3px 6px #0000001C;
  margin-bottom: 2%;
}

h1.heroName {
  font-size: 5rem;
  font-family: Helvetica, Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  text-transform: uppercase;
  font-weight: bolder;
}

h2.heroLocation {
  font-size: 1.5rem;
  font-family: Helvetica, Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  text-transform: uppercase;
  font-weight: bolder;
}

h3.heroSkill {
  font-size: .9rem;
  font-family: Helvetica, Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
}

.socialLinksContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  width: 50%;
  margin: 0 auto;
}

.socialLinks {
  width: 30px;
  margin: 3px;
}

.nav-bar {
  a {
    color: black;
  }

  .nav-link {
    line-height: 20px;
  }
  .nav-icon {
    height: 20px;
  }

  .nav-item {
    margin-right: 10px;
  }
}
